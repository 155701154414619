<template>
  <div>
    <kandidat-menu
      :kandidat="kandidatData"
      :active-item="activeItem"
    />
    <b-card no-body>
      <b-card-body>
        {{ $t('UkupanDug') }}: {{ ukupnoDug }}
        <div class="demo-inline-spacing">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-novi-zapis
            variant="warning"
            size="sm"
            @click="noviZapis('zaduzenje')"
          >
            {{ $t('NovoZaduzenje') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-novi-zapis
            variant="success"
            size="sm"
            @click="noviZapis('razduzenje')"
          >
            {{ $t('NovoRazduzenje') }}
          </b-button>
        </div>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-body :title="$t('Kandidati')">
        <div>
          <b-table
            :items="items"
            :fields="fields"
            striped
            responsive
            class="mb-0"
          >
            <template #cell(detalji)="row">
              <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
              <b-form-checkbox
                v-model="row.detailsShowing"
                @change="row.toggleDetails"
              />
            </template>
            <template #cell(id)="data">
              <b-button
                variant="danger"
                size="sm"
                @click="modalBrisanje(data.item.id, data.item.ugovor)"
              >
                {{ $t("Obrisi") }}
              </b-button>
              <!-- <b-button
                v-if="data.item.razduzenje !== ''"
                variant="primary"
                size="sm"
                @click="print()"
              >
                {{ $t("Priznanica") }}
              </b-button> -->
            </template>

            <!-- full detail on click -->
            <template #row-details="row">
              <b-card>
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click="row.toggleDetails"
                >
                  {{ $t("SakrijDetalje") }}
                </b-button>
              </b-card>
            </template>

            <template #cell(status)="data">
              <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
              </b-badge>
            </template>
            <template
              v-slot:custom-foot
            >
              <tr
                class="p-3 mb-2 bg-success text-dark"
              >
                <td
                  colspan="3"
                >
                  &nbsp;
                </td>
                <td
                  class="text-right"
                >
                  {{ ukupnoZaduzenje }}
                </td>
                <td
                  class="text-right"
                >
                  {{ ukupnoRazduzenje }}
                </td>
                <td
                  colspan="2"
                >
                  &nbsp;
                </td>
              </tr>
            </template>
          </b-table>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      id="modal-novi-zapis"
      ref="modal-novi-zapis"
      cancel-variant="outline-secondary"
      :ok-title="$t('Unesi')"
      :cancel-title="$t('Otkazi')"
      centered
      :title="modalZaduzenjeTitle"
      size="lg"
      @ok="snimiNoviZapis"
    >
      <b-form>
        <b-form-group>
          <label for="datum_novog_zapisa">{{ $t('Datum') }}:</label>
          <b-form-datepicker
            id="datum_novog_zapisa"
            v-model="dataNoviZapis.datum"
            :placeholder="$t('Datum')"
            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
            :locale="this.$i18n.locale"
            :hide-header="hideHeader"
            today-button
            reset-button
            close-button
            show-decade-nav
            :label-today-button="$t('Danas')"
            :label-reset-button="$t('Ponisti')"
            :label-close-button="$t('Zatvori')"
            :label-prev-year="$t('PrethodnaGodina')"
            :label-prev-month="$t('PrethodniMesec')"
            :label-next-year="$t('SledecaGodina')"
            :label-next-month="$t('SledeciMesec')"
            :label-current-month="$t('TekuciMesec')"
            :label-prev-decade="$t('MinusDesetGodina')"
            :label-next-decade="$t('PlusDesetGodina')"
            label-help=""
            no-flip
          />
          <small
            v-if="formErrors['datum']"
            class="text-danger"
          >
            {{ $t(formErrors['datum'][0]) }}
          </small>
        </b-form-group>
        <b-form-group
          v-if="dataNoviZapis.vrsta === 'razduzenje'"
          :label="$t('TipRacuna')"
        >
          <b-button-group
            size="sm"
          >
            <b-button
              v-for="(tip, index) in tipoviRacuna"
              :key="index"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="tipRacuna === tip.sifra ? 'primary' : 'outline-primary'"
              @click="izaberiTipRacuna(tip.sifra)"
            >
              {{ tip.naziv }}
            </b-button>
          </b-button-group>
        </b-form-group>
        <!-- <b-form-group>
          <label for="artikal">{{ $t('Artikal') }}</label>
          <v-select
            v-model="dataNoviZapis.artikal"
            :placeholder="$t('Artikal')"
            :options="artikli"
            :clearable="false"
            input-id="artikal"
          />
        </b-form-group> -->
        <!-- <b-form-group
          label="poreska_stopa"
          label-for="poreska_stopa"
        >
          <v-select
            v-model="dataNoviZapis.poreska_stopa"
            :placeholder="$t('PoreskaStopa')"
            :options="poreskeStope"
            :clearable="false"
            input-id="poreska_stopa"
          />
        </b-form-group> -->
        <b-form-group>
          <label for="opis_novog_zapisa">{{ $t('Opis') }}</label>
          <b-form-input
            id="opis_novog_zapisa"
            v-model="dataNoviZapis.opis"
            :placeholder="$t('Opis')"
          />
          <small
            v-if="formErrors['opis']"
            class="text-danger"
          >
            {{ $t(formErrors['opis'][0]) }}
          </small>
        </b-form-group>
        <b-form-group>
          <label for="iznos_novog_zapisa">{{ $t('Iznos') }}</label>
          <b-form-input
            id="iznos_novog_zapisa"
            v-model="dataNoviZapis.iznos"
            :placeholder="$t('Iznos')"
            type="number"
          />
          <small
            v-if="formErrors['iznos']"
            class="text-danger"
          >
            {{ $t(formErrors['iznos'][0]) }}
          </small>
        </b-form-group>
        <b-form-group
          v-if="dataNoviZapis.vrsta === 'razduzenje'"
          :label="$t('NacinPlacanja')"
        >
          <b-button-group
            size="sm"
          >
            <b-button
              v-for="(np, index) in naciniPlacanja"
              :key="index"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="nacinPlacanja === np.sifra ? 'primary' : 'outline-primary'"
              @click="izmeniNacinPlacanja(np.sifra)"
            >
              {{ np.naziv }}
            </b-button>
          </b-button-group>
        </b-form-group>
        <b-form-group
          :label="$t('Napomena')"
          label-for="napomena"
        >
          <b-form-textarea
            id="napomena"
            v-model="dataNoviZapis.napomena"
            :placeholder="$t('Napomena')"
            rows="3"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <div
      id="tekstRacuna"
    >
      <div
        style="white-space: pre-wrap; word-wrap: break-word; font-family: inherit;"
      >
        {{ racunZaStampu }}
      </div>
    </div>
    <b-modal
      id="modal-brisanje-finansija"
      ref="modal-brisanje-finansija"
      ok-variant="danger"
      cancel-variant="outline-secondary"
      :ok-title="$t('Obrisi')"
      :cancel-title="$t('Otkazi')"
      centered
      :title="$t('ObrisiFinansije')"
      size="lg"
      @ok="obrisiFinansije"
    >
      <div class="d-block text-left">
        <p>
          {{ $t('DaLiSteSigutniDaZeliteDaObriseteFinansije:') }}
        </p>
        <p
          v-if="podaciFinansijeBrisanje.ugovor !== null"
        >
          {{ $t('FinansijeSuVezaneZaUgovorOdDana') }} {{ typeof podaciFinansijeBrisanje.ugovor !== 'undefined' ? podaciFinansijeBrisanje.ugovor.datum : '' }}
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BButtonGroup,
  BCardBody,
  BBadge,
  BCard,
  BModal,
  VBModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormTextarea,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import KandidatMenu from '../kandidati/KandidatMenu.vue'

export default {
  components: {
    BTable,
    BButton,
    BButtonGroup,
    BFormCheckbox,
    BCardBody,
    BBadge,
    BModal,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormTextarea,
    // vSelect,
    'kandidat-menu': KandidatMenu,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      activeItem: 'finansije',
      fields: [
        { key: 'detalji', label: '' },
        { key: 'datum', label: this.$i18n.t('Datum') },
        { key: 'opis', label: this.$i18n.t('Opis') },
        { key: 'zaduzenje', label: this.$i18n.t('Zaduzenje'), class: 'text-right' },
        { key: 'razduzenje', label: this.$i18n.t('Razduzenje'), class: 'text-right' },
        { key: 'napomena', label: this.$i18n.t('Napomena') },
        { key: 'id', label: 'Akcije' },
      ],
      naciniPlacanja: [
        { naziv: this.$i18n.t('Kes'), sifra: 'kes' },
        { naziv: this.$i18n.t('Kartica'), sifra: 'kartica' },
        { naziv: this.$i18n.t('Cek'), sifra: 'cek' },
        { naziv: this.$i18n.t('Elektronski'), sifra: 'elektronski' },
        { naziv: this.$i18n.t('Vaucer'), sifra: 'vaucer' },
        { naziv: this.$i18n.t('MobilniNovac'), sifra: 'mobilni_novac' },
        { naziv: this.$i18n.t('Ostalo'), sifra: 'ostalo' },
      ],
      tipoviRacuna: [
        { naziv: this.$i18n.t('Normal'), sifra: 'normal' },
        { naziv: this.$i18n.t('Avans'), sifra: 'avans' },
      ],
      /* eslint-disable global-require */
      items: [],
      kandidatData: {},
      modalZaduzenjeTitle: '',
      hideHeader: true,
      dataNoviZapis: { datum: moment(new Date()).format('YYYY-MM-DD') },
      formErrors: [],
      notificationVariant: 'success',
      notificationTitle: this.$i18n.t('Uspesno'),
      notificationMessage: this.$i18n.t('NoviZapisJeUspesnoDodat'),
      ukupnoZaduzenje: 0,
      ukupnoRazduzenje: 0,
      ukupnoDug: 0,
      artikli: [],
      poreskaStopa: '',
      poreskeStope: ['А', 'Ђ', 'Г', 'Е'],
      nacinPlacanja: 0,
      racunZaStampu: '',
      tipRacuna: 'Normal',
      podaciFinansijeBrisanje: {},
    }
  },
  watch: {
    'dataNoviZapis.artikal': {
      handler(artikal) {
        this.popuniDataNoviZapisArtikal(artikal)
      },
    },
  },
  mounted() {
    this.pokupiListu()
  },
  methods: {
    print(tekst) {
      this.racunZaStampu = tekst
      // Pass the element id here
      this.$htmlToPaper('tekstRacuna')
    },
    izmeniNacinPlacanja(nacinPlacanja) {
      this.nacinPlacanja = nacinPlacanja
    },
    izaberiTipRacuna(tip) {
      this.tipRacuna = tip
    },
    popuniDataNoviZapisArtikal(artikal) {
      this.dataNoviZapis.poreska_stopa = ''
      this.dataNoviZapis.opis_artikla = ''
      this.dataNoviZapis.iznos = ''

      if (typeof artikal !== 'undefined') {
        this.dataNoviZapis.poreska_stopa = artikal.poreska_stopa
        this.dataNoviZapis.opis = artikal.opis_artikla
        this.dataNoviZapis.iznos = artikal.cena
      }
    },
    noviZapis(tip) {
      this.formErrors = []
      this.modalZaduzenjeTitle = this.$i18n.t(tip)
      this.dataNoviZapis.vrsta = tip
      this.dataNoviZapis.kandidat_id = this.$route.params.id
    },
    snimiNoviZapis() {
      this.dataNoviZapis.nacin_placanja = this.nacinPlacanja
      this.dataNoviZapis.tip_racuna = this.tipRacuna
      this.dataNoviZapis.transactionType = 'Sale'
      this.$http.post('/autoskole-administratori/kandidat/finansije', this.dataNoviZapis)
        .then(() => {
          this.artikli = []
          this.dataNoviZapis = {}
          this.dataNoviZapis.datum = moment(new Date()).format('YYYY-MM-DD')
          this.notificationVariant = 'success'
          this.notificationTitle = this.$i18n.t('Uspesno')
          this.notificationMessage = this.$i18n.t('NoviZapisJeUspesnoDodat')
          this.prikaziPoruku()
          this.pokupiListu()
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.notificationVariant = 'danger'
            this.notificationTitle = this.$i18n.t('Neuspesno')
            this.notificationMessage = this.$i18n.t('MolimoPopuniteSvaPolja')
            this.formErrors = error.response.data.errors
            this.$refs['modal-novi-zapis'].show()
            this.prikaziPoruku()
          }
        })
    },
    prikaziPoruku() {
      const variant = this.notificationVariant
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.notificationTitle,
          icon: 'InfoIcon',
          text: this.notificationMessage,
          variant,
        },
      },
      {
        position: 'top-center',
      })
    },
    pokupiListu() {
      this.$http.get(`/autoskole-administratori/kandidat/finansije/${this.$route.params.id}`).then(response => {
        this.items = response.data.finansije
        this.kandidatData = response.data.kandidat
        this.ukupnoZaduzenje = response.data.ukupnoZaduzenje
        this.ukupnoRazduzenje = response.data.ukupnoRazduzenje
        this.ukupnoDug = response.data.ukupnoDug
      })

      /* this.$http.post('/autoskole-administratori/web-esir-api-kreiraj-racun', this.dataNoviZapis)
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.notificationVariant = 'danger'
            this.notificationTitle = this.$i18n.t('Neuspesno')
            this.notificationMessage = this.$i18n.t('MolimoPopuniteSvaPolja')
            this.formErrors = error.response.data.errors
            this.$refs['modal-novi-zapis'].show()
            this.prikaziPoruku()
          }
        }) */
      this.$http.get('/autoskole-administratori/sifarnici-artikala').then(response => {
        Object.entries(response.data).forEach(value => {
          this.artikli.push(
            {
              value: value[1].id,
              label: `${value[1].naziv_artikla}`,
              poreska_stopa: `${value[1].poreska_stopa}`,
              opis_artikla: `${value[1].opis_artikla}`,
              cena: `${value[1].cena}`,
            },
          )
        })
      })
    },
    priznanica(id) {
      this.$http.get(`/autoskole-administratori/kandidat/finansije/priznanica/${id}`, { responseType: 'arraybuffer' }).then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Priznanica.pdf'
        link.click()
        this.$vs.loading.close()
      })
    },
    modalBrisanje(id, ugovor) {
      this.podaciFinansijeBrisanje = {
        id,
        ugovor,
      }
      this.$bvModal.show('modal-brisanje-finansija')
    },
    obrisiFinansije() {
      this.$http.delete(`/autoskole-administratori/kandidat/finansije/${this.podaciFinansijeBrisanje.id}`).then(() => {
        this.$bvModal.hide('modal-brisanje-finansija')
        this.pokupiListu()
      })
    },
  },
}
</script>
